<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreateAdmin"
        variant="primary"
        @click="openModal"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :resource="resource"
      :columns="columns"
      :filters="filters"
      hide-per-page
    />
    <b-modal
      v-model="modalIsOpen"
      title="Create Admin"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <new-admin-editor @submit="handleSubmit" />
    </b-modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { VxTable, VxCellDate, VxCellButtons, VxCellLink } from '@/components/table'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import VxButton from '@/components/buttons/VxButton'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import NewAdminEditor from '@/views/admins/admins/NewAdminEditor'
import { admins, passDataToResource, useResource } from '@/services/resources'
import { useModalEditor } from '@/services/modal'
import { filterTypes, sortOrders } from '@/services/table'
import { buildRoute, path } from '@/router'
import store from '@/store'
import { boolStatusesOptions } from '@/services/utils'

export default {
  name: 'Admins',
  components: {
    NewAdminEditor,
    VxButton,
    VxPageTitleActions,
    VxTable,
    VxCellDate
  },
  setup () {
    const tableRef = ref(null)

    const resource = admins.getAll

    const { can: canCreateAdmin } = useResource(admins.create)
    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()

    const handleSubmit = () => {
      closeModal()
      tableRef.value.refresh()
    }

    const currentUserId = store.getters['user/id']

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'name',
        component: VxCellLink,
        tdAttr: (name, key, { id, first_name, last_name }) => ({
          label: `${first_name} ${last_name}`,
          href: buildRoute(path.admin, { params: { id } })
        })
      },
      {
        key: 'login',
        sortable: true
      },
      {
        key: 'role.title',
        label: 'Role'
      },
      {
        key: 'is_banned',
        label: 'Status',
        component: VxCellButtons,
        tdAttr: (value, key, item) => ({
          buttons: statusButton(item)
        })
      },
      {
        key: 'email',
        component: VxCellLink,
        tdAttr: (email) => {
          if (!email) return {}
          return {
            label: email,
            href: `mailto:${email}`
          }
        }
      },
      {
        key: 'phone',
        component: VxCellLink,
        tdAttr: (phone) => {
          if (!phone) return {}
          return {
            label: phone,
            href: `tel:${phone}`
          }
        }
      },
      {
        key: 'default_page',
        component: VxCellLink,
        tdAttr: (defaultPage) => {
          if (!defaultPage) return {}
          return {
            label: 'Link',
            href: defaultPage
          }
        }
      },
      {
        key: 'created_at',
        label: 'Registered At',
        component: VxCellDate
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'login',
        type: filterTypes.text
      },
      {
        key: 'banned',
        type: filterTypes.select,
        options: boolStatusesOptions,
        optionsLabel: 'label',
        reduce: ({ value }) => value
      }
    ]

    const statusButton = ({ id, is_banned }) => {
      const button = { ...is_banned ? buttons.banned : buttons.active }

      if (currentUserId === id) return [{ ...button, disabled: true }]

      const resource = passDataToResource(admins.toggleBan, {
        requestParams: {
          urlParams: { id },
          data: { is_banned: !is_banned }
        }
      })

      return [{ ...button, resource }]
    }

    return {
      tableRef,
      resource,
      columns,
      filters,

      canCreateAdmin,
      modalIsOpen,
      entity,
      openModal,
      closeModal,
      handleSubmit
    }
  }
}
</script>
