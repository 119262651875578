<template>
  <portal :order="order" to="page-title-actions">
    <slot />
  </portal>
</template>

<script>
export default {
  name: 'VxPageTitleActions',
  props: {
    order: {
      type: Number,
      default: 1
    }
  }
}
</script>
