<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.login"
      :rules="rules.login"
      name="login"
      label="Login"
    />

    <vx-input
      v-model="formValue.password"
      :rules="rules.password"
      name="password"
      type="password"
      label="Password"
    />

    <vx-select-resource
      v-model="formValue.role_id"
      :resource="rolesResource"
      :rules="rules.role_id"
      :reduce="({ id }) => id"
      options-label="title"
      name="role_id"
      label="Role"
    />

    <vx-input
      v-model="formValue.first_name"
      :rules="rules.first_name"
      name="first_name"
      label="First Name"
    />

    <vx-input
      v-model="formValue.last_name"
      :rules="rules.last_name"
      name="last_name"
      label="Last Name"
    />

    <vx-input
      v-model="formValue.email"
      :rules="rules.email"
      name="email"
      label="Email"
    />

    <vx-input
      v-model="formValue.phone"
      :rules="rules.phone"
      name="phone"
      label="Phone"
    />

    <vx-input
      v-model="formValue.default_page"
      :rules="rules.default_page"
      name="default_page"
      label="Default Page"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { admins, roles } from '@/services/resources'
import { reactive } from '@vue/composition-api'
import { VxForm, VxInput, VxSelectResource } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { apiDefaultValidators } from '@/services/form/validationService'

export default {
  name: 'NewAdminEditor',
  components: {
    VxForm,
    VxInput,
    VxButton,
    VxSelectResource
  },
  setup (props, { emit }) {
    const formResource = admins.create
    const rolesResource = roles.getAll

    const formValue = reactive({
      login: '',
      email: '',
      password: '',
      phone: '',
      first_name: '',
      last_name: '',
      default_page: '',
      role_id: ''
    })

    const { maxTinyTextLength, phonePattern } = apiDefaultValidators
    const rules = {
      login: {
        required: true,
        max: maxTinyTextLength
      },
      email: {
        email: true,
        max: maxTinyTextLength
      },
      password: {
        required: true,
        max: maxTinyTextLength
      },
      phone: {
        max: 18,
        regex: phonePattern
      },
      first_name: {
        required: true,
        max: maxTinyTextLength
      },
      last_name: {
        required: true,
        max: maxTinyTextLength
      },
      default_page: {
        required: true,
        max: maxTinyTextLength
      },
      role_id: {
        required: true,
        numeric: true
      }
    }

    const handleSubmit = ([, res]) => {
      if (res) {
        emit('submit', res)
      }
    }

    return {
      formValue,
      formResource,
      rules,
      rolesResource,
      handleSubmit
    }
  }
}
</script>
